
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {toast} from "react-toastify";
import Api from "./api";
const ProtectedRoute = (props) => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const checkUserToken = async () => {
        const userToken = localStorage.getItem('accesstoken');
        if (!userToken || userToken === 'undefined') {
            setIsLoggedIn(false);
            return navigate('/authentication/login');
        } else {
            try {
                const response = await Api({
                    url: '/authentication/check-login',
                    method: 'get'
                })
        
                localStorage.setItem('accesstoken', response.data.Data.accessToken);
            } catch (error) {
                toast.error(error.response.data.Error.message);
                setIsLoggedIn(false);
                return navigate('/authentication/login');
            }
        }
        setIsLoggedIn(true);
    }
    useEffect(() => {
        checkUserToken().then();
    // eslint-disable-next-line
    }, [isLoggedIn]);
    return (
        <React.Fragment>
            {
                isLoggedIn ? props.children : null
            }
        </React.Fragment>
    );
}
export default ProtectedRoute;
