import React from 'react';
import { Container } from 'react-bootstrap';
import {Outlet} from "react-router-dom";
const ProductList = () => {
    return (
        <React.Fragment>
            <Container className='py-5'>
                <h3 className='fw-normal'>Welcome Product List.</h3>
            </Container>
            <Outlet></Outlet>
        </React.Fragment>
    )
}
export default ProductList;
