import { useForm } from "react-hook-form"
import React from "react";
import { Button, Col, Container, Form, FormGroup, FormLabel, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {toast} from "react-toastify";
import Api from "../../utils/api";

const Login = () => {
    const navigate = useNavigate();
    const {
        register, handleSubmit
    } = useForm()
    const onsubmit = async (data) => {
        // http://34.49.14.113/fd-backend-service/health-check
        try {
            const response = await Api({
                method: 'post',
                url: '/authentication/login',
                body: data,
            });
            toast.success('Admin login successfully');
            localStorage.setItem('accesstoken', response.data.Data.accessToken);
            return navigate('/');
        } catch (error) {
            toast.error(error.response.data.Error.message);
        }
    }
    return (
        <React.Fragment>
            <Container className="my-5">
                <h2 className="fw-normal mb-5">Login To Fascinating Diamond</h2>
                <Row>
                    <Col md={{span: 6}}>
                        <Form id="loginForm" onSubmit={handleSubmit(onsubmit)}>
                            <FormGroup className="mb-3">
                                <FormLabel htmlFor={'login-email-address'}>Email Address</FormLabel>
                                <input type={'email'} className="form-control" id={'login-email-address'} name="login-email-address" required {...register("emailAddress")} />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormLabel htmlFor={'login-password'}>Password</FormLabel>
                                <input type={'password'} className="form-control" id={'login-password'} name="password" required {...register("password")}/>
                            </FormGroup>
                            <Button type="submit" className="btn-success mt-2" id="login-btn">Login</Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}
export default Login;
