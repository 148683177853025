import React from 'react';
import {Button, Col, Container, Form, FormGroup, FormLabel, Row} from 'react-bootstrap';
// import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form"
import {toast} from "react-toastify";
import Api from '../../utils/api';


const CreateProduct = () => {
    // const navigate = useNavigate();
    const {
        register, handleSubmit
    } = useForm()
    const onSubmit = async (data) => {
        try {
            await Api({
                method: 'post',
                url: '/product/create-product-by-sku',
                params: {
                    sku: data.productSKU
                },
            });
            toast.success('Product sync started successfully');
        } catch (error) {
            toast.error(error.response.data.Error.message);
        }
    }
    return (
        <React.Fragment>
            <Container className="my-5">
                <h2 className="fw-normal mb-5">Sync Product</h2>
                <Row>
                    <Col md={{span: 6}}>
                        <Form id="loginForm" onSubmit={handleSubmit(onSubmit)}>
                            <FormGroup className="mb-3">
                                <FormLabel htmlFor={'product-sku'}>Product Sku</FormLabel>
                                <input type={'text'} className="form-control" id={'product-sku'} name="product-sku" required {...register("productSKU")} />
                            </FormGroup>
                            <Button type="submit" className="btn-success mt-2" id="login-btn">Start Product Sync</Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default CreateProduct;
