import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './authentication/login/Login';
import Auth from './authentication/Authentication';
import ProtectedRoute from './utils/ProtectedRoute';
import Home from './dashboard/home/Home';
import ProductList from "./dashboard/product/ProductList";
import CreateProduct from "./dashboard/product/CreateProduct";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <ToastContainer />
      <BrowserRouter basename={'/'}>
          <Routes>
              <Route path='/authentication' element={<Auth />}>
                  <Route path='login' element={<Login />} />
              </Route>
              <Route path="/" element={<App />}>
                  <Route path='' element={
                      <ProtectedRoute>
                          {/* <Home /> */}
                          <CreateProduct />
                      </ProtectedRoute>
                  } />
                  <Route path='products' element={
                      <ProtectedRoute>
                          <ProductList />
                      </ProtectedRoute>
                  }/>
                  <Route path='products/create-product' element={
                      <ProtectedRoute>
                          <CreateProduct />
                      </ProtectedRoute>
                  } />
              </Route>
          </Routes>
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
