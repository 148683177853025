import React from "react";
import { Outlet } from "react-router-dom";
import AuthFooter from "./footer/Footer";
import AuthNavbar from "./navbar/Navbar";
const Authentication = () => {
    return (
        <React.Fragment>
            <AuthNavbar />
            <Outlet />
            <AuthFooter />
        </React.Fragment>
    );
}
export default Authentication;
