import axios from "axios";



const Api = async ({method, url, body=undefined, params=undefined, headers={}}) => {
    const userToken = localStorage.getItem('accesstoken');
    return await axios({
        method: method,
        url: `${process.env.NODE_ENV === 'production' ? 'https://admin-app.fascinatingdiamonds.com' : 'http://localhost:8080'}/fd-backend-service${url}`,
        data: body,
        params: params,
        headers: {
            ...headers,
            accesstoken: userToken || ''
        }
    });

    // return await axios.get('http://localhost:8080/fd-backend-service/authentication/check-login',
    // {
    //     headers: {
    //         'accesstoken': userToken || ''
    //     }
    // });
}


export default Api;
