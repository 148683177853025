import React from "react";
import { Nav } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';

import {Link} from "react-router-dom";
const DashboardNavbar = () => {
    return (
        <React.Fragment>
            <Navbar expand={false} className="navbar navbar-dark" style={{height: '62px', backgroundColor: '#d04545'}}>
                <Container>
                    <Navbar.Brand>Fascinating Diamonds</Navbar.Brand>
                    {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand`}
                        aria-labelledby={`offcanvasNavbarLabel-expand`}
                        placement="start"
                        className={"shadow-lg"}
                    >
                        <Offcanvas.Header closeButton className={"bg-dark"}>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand`} className={"text-light"}>
                                Fascinating Diamond
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-end flex-grow-1 pe-3">
                                <Nav.Link><Link to={"/"} className={"font-weight-bold text-decoration-none text-dark"}>Home</Link></Nav.Link>
                                <NavDropdown
                                    title="Product"
                                    id={`offcanvasNavbarDropdown-expand`}
                                    className={""}
                                >
                                    <Nav className="px-3 py-0 my-0">
                                        <Nav.Link><Link to={"/products"} className={"font-weight-bold text-decoration-none text-dark"}>All Products</Link></Nav.Link>
                                        <Nav.Link><Link to={"/products/create-product"} className={"font-weight-bold text-decoration-none text-dark"}>Create Product</Link></Nav.Link>
                                    </Nav>
                                </NavDropdown>
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </React.Fragment>
    );
}
export default DashboardNavbar;
